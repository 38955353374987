<template>
  <div class="pay_page">
    <!-- 头部 -->
    <div class="header_flow">
      <div class="header_b">
        <div class="header_logo">
          <img src="../../../public/images/orenji.png" alt="" />
          <span class="widths"></span>
          <h3>オーダー支払い</h3>
        </div>
      </div>
    </div>
    <div class="pay_W">
      <div class="pay_content">
        <h2>オンライン決済</h2>
        <div>
          <h3 style="padding-top: 20px">オーダー詳細:</h3>
          <ul class="meun_orde">
            <li
              class="ord"
              v-for="item in ord"
              :key="item.ID"
              v-show="item.ID == frel"
            >
              <div>
                <span class="items">{{ item.title }}</span>
                <span
                  v-show="frel < 9"
                  class="ems"
                  style="margin-left: 10px; margin-top: 2px"
                  >{{ item.key }}</span
                >
              </div>
              <div v-show="frel < 9" style="padding: 10px 0px">
                <span class="items">帯域幅: </span>
                <span class="ems" style="margin-left: 10px; margin-top: 2px">{{
                  item.value
                }}</span>
              </div>
              <div>
                <span class="items">{{ item.money }}</span>
                <span class="ems" style="margin-left: 10px; margin-top: 2px">{{
                  item.number
                }}</span>
              </div>
            </li>
          </ul>
        </div>
        <h3 style="padding-top: 20px">支払い方法:</h3>
        <ul class="meun">
          <li
            :class="['lis', [tab === index ? 'active' : '']]"
            v-for="(item, index) in lis"
            :key="index"
            @click="hanlePaysl(index)"
          >
            {{ item }}
          </li>
        </ul>
        <div class="qdcode">
          <div>
            <vue-qr
              :logo-src="logoSrc"
              :size="180"
              :margin="0"
              :auto-color="true"
              :dot-scale="1"
              :text="apply"
              :whiteMargin="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  name: "pay",
  components: {
    VueQr,
  },
  data() {
    return {
      tab: 0,
      lis: ["AliPay"],
      ord: [
        {
          ID: "1",
          title: "配す:",
          key: "1C2G",
          value: "2Mbps",
          money: "月額料金:",
          number: "2210.48円",
        },
        {
          ID: "2",
          title: "配す:",
          key: "2C4G",
          value: "4Mbps",
          money: "月額料金:",
          number: "4383.36円",
        },
        {
          ID: "3",
          title: "配す:",
          key: "4C8G",
          value: "4Mbps",
          money: "月額料金:",
          number: "5260.27円",
        },
        {
          ID: "4",
          title: "配す:",
          key: "8C16G",
          value: "8Mbps",
          money: "月額料金:",
          number: "10482.57円",
        },
        {
          ID: "5",
          title: "配す:",
          key: "16C32G",
          value: "8Mbps",
          money: "月額料金:",
          number: "13991.48円",
        },
        {
          ID: "6",
          title: "配す:",
          key: "32C64G",
          value: "8Mbps",
          money: "月額料金:",
          number: "21008.48円",
        },
        {
          ID: "7",
          title: "配す:",
          key: "デフォルト",
          value: "デフォルト",
          money: "月額料金:",
          number: "136.66円",
        },
        {
          ID: "8",
          title: "配す:",
          key: "デフォルト",
          value: "1Mbps",
          money: "月額料金:",
          number: "527.88円",
        },
        { ID: "9", money: "金額:", number: "175円" },
        { ID: "10", money: "金額:", number: "6180円" },
        { ID: "11", money: "金額:", number: "7130円" },
        { ID: "12", money: "金額:", number: "8826円" },
        { ID: "13", money: "金額:", number: "14262円" },
        { ID: "14", money: "金額:", number: "26425円" },
        { ID: "15", money: "金額:", number: "39629円" },
        { ID: "16", money: "金額:", number: "79258円" },
      ],
      logoSrc: "",
      apply: "",
      frel: 1,
    };
  },
  mounted() {
    this.hanlePay();
  },
  methods: {
    async hanlePay() {
      const path = this.$route.query;
      this.frel = path.number;
      const pb = {
        money: parseInt(path.money),
      };
      const { data: res } = await this.$http.post(
        "/api/api/orenji/bluepoint/pay",
        pb
      );
      this.apply = res.qrcode;
    },
    hanlePaysl(index) {
      this.tab = index;
    },
  },
};
</script>

<style lang="less" scoped>
.pay_page {
  width: 100%;
  height: 100%;
  color: #333;
  background-color: #efefef;
  .pay_W {
    width: 1200px;
    margin: 0px auto;
    background-color: #fff;
    padding: 50px 0px;
    margin-top: 30px;
    border-radius: 4px;
    .pay_content {
      padding-left: 350px;
    }
  }
}
.qdcode {
  padding-top: 20px;
  position: relative;
  width: 200px;
  height: 200px;
  // background: #02a9f1;
  border-radius: 6px;
  //   border: 2px solid #f08c1b;
  //   padding: 8px;
  .btn-c {
    background-color: #f08c1b;
  }
  .tips {
    width: 200px;
    height: 30px;
    background-color: #f08c1b;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
.header_flow {
  height: 60px;
  width: 100%;
  background-color: #fff;
  .header_b {
    width: 1200px;
    margin: 0px auto;
    .header_logo {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      .widths {
        display: flex;
        width: 1px;
        height: 40px;
        background-color: #efefef;
        margin: 0px 20px;
      }
    }
  }
}
.meun {
  height: 35px;
  margin-top: 10px;
  .lis {
    float: left;
    width: 100px;
    height: 35px;
    border: 1px solid #eeee;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
}
.active {
  background-color: #f08c1b;
}
.meun_orde {
  .ord {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    .items {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      width: 150px;
    }
    .ems {
      font-size: 16px;
    }
  }
}
</style>